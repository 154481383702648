import { Button, Form, Row } from "react-bootstrap";
import React, { useCallback, useContext, useState } from "react";

import { DispatchContext } from "./../context/AuthContext";
import { FormField } from "./FormField";
import { PropTypes } from "prop-types";
import { Redirect } from "react-router-dom";
import { apiPost } from "./../common/Utils";

export function LoginForm({ onSuccess, onError, redirect }) {
  const [data, setData] = useState({});
  const [doRedirect, setDoRedirect] = useState(false);

  const { dispatch } = useContext(DispatchContext);
  const tryLogin = useCallback(() => {
    apiPost(`Login`, data).then(
      (res) => {
        if (res.ok) {
          dispatch({ action: "LOGIN", payload: res.body });
          setDoRedirect(true);
          onSuccess();
        } else {
          throw new Error(res.body);
        }
      },
      (reason) => {
        onError("Feil ved innlogging");
        dispatch({
          action: "LOGOUT",
          payload: "Feil brukernavn eller passord, prøv igjen",
        });
      }
    );
  }, [data, onSuccess, onError, dispatch]);

  if (doRedirect) return <Redirect to={redirect} />;

  return (
    <Form>
      <FormField
        name="userName"
        label="Brukernavn"
        type="text"
        placeholder="Username"
        data={data}
        setData={setData}
        autoComplete="username"
      />
      <FormField
        name="password"
        label="Passord"
        type="password"
        placeholder="Password"
        autoComplete="current-password"
        data={data}
        setData={setData}
      />
      <Row className="mt-3">
        <Button variant="dark" onClick={tryLogin} type="button">
          Logg inn
        </Button>
      </Row>
    </Form>
  );
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  redirect: PropTypes.string.isRequired,
};

LoginForm.defaultProps = {
  onSuccess: () => {},
  onError: (err) => {},
  redirect: "/",
};

export default LoginForm;
