import moment from "moment-timezone";
import { useCallback, useState } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
const { allowedMaxDays } = DateRangePicker;

export function AVDateRangePicker({ filter, handleChange }) {
  const [dtRange, setDtRange] = useState([
    moment(filter?.startTime).tz("Europe/Oslo").toDate(),
    moment(filter?.endTime).tz("Europe/Oslo").toDate(),
  ]);

  const updateDtRange = useCallback(
    (selectedDtRange) => {
      setDtRange(selectedDtRange);
      const [start, end] = selectedDtRange;
      let data = {
        startTime: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
        endTime: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
      };
      handleChange(data);
    },
    [handleChange]
  );

  return (
    <>
      <DateRangePicker
        value={dtRange}
        onChange={updateDtRange}
        placement={"bottomEnd"}
        shouldDisableDate={allowedMaxDays(7)}
        // showMeridian
        format="yyyy-MM-dd HH"
        defaultCalendarValue={dtRange}
      />
    </>
  );
}
