import React, { createContext, useReducer } from "react";

import { AuthReducer } from "./../reducers/AuthReducer";
import { getAuthenticated } from "./../common/Utils";
import { isAuthenticated } from "../common/Utils";

export const StateContext = createContext({
  authenticatedUser: {},
  authorized: false,
});

export const DispatchContext = createContext({
  dispatch: (action, payload) => {},
});

export function AuthContext(props) {
  const [state, dispatch] = useReducer(AuthReducer, {
    authenticatedUser: getAuthenticated(),
    authorized: isAuthenticated(),
  });

  return (
    <DispatchContext.Provider value={{ dispatch }}>
      <StateContext.Provider value={{ state }}>
        {props.children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}
