import "./AVTraceMap.css";
import "dc/dc.css";
import "ol/ol.css";
import React from "react";
import { createContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { createRef } from "react";

import ImageLayer from "ol/layer/Image";
import Map from "ol/Map";
import Static from "ol/source/ImageStatic";
import View from "ol/View";
import { useMemo } from "react";

import Projection from "ol/proj/Projection";
import { addCoordinateTransforms } from "ol/proj";
import { getCenter } from "ol/extent";
import { defaults as defaultControls } from "ol/control.js";
import { useParams } from "react-router-dom";
import { apiProjectImage } from "../common/Utils";
import { HeatmapLayer } from "./HeatmapLayer";
import { AVTraceCtrlZoomExtent } from "./AVTraceCtrlZoomExtent";
import { Col, Form, Row } from "react-bootstrap";

export const AVTraceMapContext = createContext();

export function AVTraceMap({
  imageHeight,
  imageWidth,
  detectionHeight,
  detectionWidth,
  crossfilter,
  valueProp,
}) {
  const ref = createRef(null);
  const [state, setState] = useState();
  const [radius, setRadius] = useState(5);
  const [blur, setBlur] = useState(20);
  const [opacity, setOpacity] = useState(0.5);
  const { projectId } = useParams();
  const [init, setInit] = useState(false);

  // Load image name
  const mapImage = useMemo(() => apiProjectImage(projectId), [projectId]);

  // Calculate view extent etc.
  const { viewExtent, extent, viewProjection, dataProjection } = useMemo(() => {
    var rW = imageWidth / detectionWidth;
    var rH = imageHeight / detectionHeight;
    var extent = [0, 0, imageWidth, imageHeight];
    var viewExtent = [0, -imageHeight, imageWidth, 0];

    var dataProjection = new Projection({
      code: "itineris-camera",
      units: "pixels",
      extent: extent,
    });

    var viewProjection = new Projection({
      code: "itineris-image",
      units: "pixels",
      extent: viewExtent,
    });

    addCoordinateTransforms(
      dataProjection,
      viewProjection,
      function (coordinate) {
        return [rW * coordinate[0], -(rH * coordinate[1])];
      },
      function (coordinate) {
        return [coordinate[0] / rW, Math.abs(coordinate[1] / rH)];
      }
    );

    return { viewExtent, extent, dataProjection, viewProjection };
  }, [imageWidth, detectionWidth, imageHeight, detectionHeight]);

  useEffect(() => {
    if (!viewExtent || !extent || !dataProjection || !viewProjection) return;
    if (init) return;
    var map = new Map({
      controls: defaultControls(),
      layers: [
        new ImageLayer({
          source: new Static({
            attributions: "© Asplan Viak 2023",
            url: mapImage,
            projection: viewProjection,
            imageExtent: viewExtent,
          }),
        }),
      ],
      target: ref.current,
      view: new View({
        projection: viewProjection,
        center: getCenter(viewExtent),
        zoom: 2,
        maxZoom: 8,
      }),
    });

    setState((s) => ({
      ...s,
      map,
      viewProjection,
      dataProjection,
      crossfilter,
      viewExtent,
    }));
    setInit(true);
  }, [
    crossfilter,
    dataProjection,
    extent,
    init,
    mapImage,
    ref,
    setState,
    viewExtent,
    viewProjection,
  ]);

  return (
    <AVTraceMapContext.Provider value={state}>
      <div className="avtrace--map-component" ref={ref}></div>
      <HeatmapLayer
        radius={radius}
        blur={blur}
        opacity={opacity}
        valueProp={valueProp}
      />
      <AVTraceCtrlZoomExtent />
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Adjust radius ({radius})</Form.Label>
            <Form.Control
              type="range"
              min={1}
              max={100}
              step={1}
              value={radius}
              onChange={(evt) => setRadius(evt.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Adjust blurring ({blur})</Form.Label>
            <Form.Control
              type="range"
              min={1}
              max={100}
              step={1}
              value={blur}
              onChange={(evt) => setBlur(evt.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Adjust opacity ({opacity})</Form.Label>
            <Form.Control
              type="range"
              min={0}
              max={1}
              step={0.05}
              value={opacity}
              onChange={(evt) => setOpacity(evt.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
    </AVTraceMapContext.Provider>
  );
}
