import { Button, Form } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from 'react';
import { apiGet, apiPost } from './../common/Utils';

import { FormField } from './FormField';
import { Loading } from './Loading';
import { PropTypes } from 'prop-types';

export function ProjectForm({ projectId, onSuccess, onError }) {

    const [data, setData] = useState();

    useEffect(() => {
        if (projectId === 0) {
            setData({});
            return;
        }
        apiGet(`admin/get-project/${projectId}`).then((res, err) => {
            if (res.ok && res.body.success === true) {
                setData(res.body.data);
                return;
            };
            throw new Error(res.body.message);
        }).catch(err => {
            onError(`Det oppstod ein feil ved lasting av prosjekter (${err.message})`)
        })
    }, [projectId, onError])

    const createProject = useCallback(() => {
        apiPost(`admin/create-project`, data).then(res => {
            if (res.ok && res.body.success) {
                onSuccess();
            } else {
                onError(res.body.message);
            }
        }).catch(err => {
            onError(err)
        })
    }, [data, onSuccess, onError])

    if (!data) return <Loading />

    return (<div className="status-container">
        <Form>
            <FormField name="id" label="ID" disabled={true} type="text" data={data} setData={setData} />
            <FormField name="title" label="Title" type="text" placeholder="Enter data" data={data} setData={setData} />
            <FormField name="description" label="Description" type="textarea" placeholder="Enter data" data={data} setData={setData} />
            <FormField name="protocol" label="Protocol" type="text" placeholder="http or https" data={data} setData={setData} />
            <FormField name="hostname" label="Hostname" type="text" placeholder="IP address or hostname without protocol or slashes" data={data} setData={setData} />
            <FormField name="port" label="Port" type="text" placeholder="For example port 80, regular http" data={data} setData={setData} />
            <FormField name="usr" label="Username" type="text" placeholder="Enter username" data={data} setData={setData} />
            <FormField name="pwd" label="Password" type="password" placeholder="Enter password" data={data} setData={setData} />
            <FormField name="object_classes" label="Object classes to detect" type="textarea" placeholder="person,bicycle" data={data} setData={setData} />
            <Button variant="primary" onClick={createProject} type="button">Lagre/oppdater</Button>
        </Form>

    </div>)

}

ProjectForm.propTypes = {
    projectId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
}

ProjectForm.defaultProps = {
    onSuccess: () => { },
    onError: (err) => { }
}

export default ProjectForm;
