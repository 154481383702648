import "./TrackMap.css";
import "dc/dc.css";
import "ol/ol.css";

import * as d3 from "d3";

import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Stroke, Style } from "ol/style";

import GeoJSON from "ol/format/GeoJSON";
// import WKT from "ol/format/WKT";
import ImageLayer from "ol/layer/Image";
import Map from "ol/Map";
import Projection from "ol/proj/Projection";
import PropTypes from "prop-types";
import Static from "ol/source/ImageStatic";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { addCoordinateTransforms } from "ol/proj";
import { getCenter } from "ol/extent";
import { useMemo } from "react";
import { defaults as defaultControls } from "ol/control.js";
import { CtrlZoomExtent } from "./CtrlZoomExtent";
import { CtrlClearVectorSource } from "./CtrlClearVectorSource";
import { InteractionDrawWkt } from "./InteractionDrawWkt";

export const TrackMapContext = createContext(null);

var style = new Style({
  stroke: new Stroke({
    color: "#FFF000",
    width: 3,
  }),
});

function TrackMap({
  crossfilter,
  colorScale,
  mapImage,
  mapImageWidth,
  mapImageHeight,
  detectionImageWidth,
  detectionImageHeight,
  wktLine,
  setWktLine,
  children,
}) {
  const ref = useRef();
  // const wktFormat = useMemo(() => new WKT(), []);
  const [dims, setDims] = useState([]);

  const [vectorSource] = useState(
    new VectorSource({
      features: [],
    })
  );
  const [vectorSourceILine] = useState(
    new VectorSource({
      features: [],
    })
  );
  const [vectorSourceDraw] = useState(
    new VectorSource({
      features: [],
    })
  );

  const [vectorLayer] = useState(
    new VectorLayer({
      source: vectorSource,
      style: function (feature) {
        var color = colorScale(feature.get("objclass").toLowerCase());
        style.getStroke().setColor(color);
        style.getStroke().setWidth(0.8);
        return style;
      },
    })
  );

  const [vectorLayerILine] = useState(
    new VectorLayer({
      source: vectorSourceILine,
      style: function (feature) {
        style.getStroke().setColor("#ff0000");
        style.getStroke().setWidth(3);
        return style;
      },
    })
  );

  const [state, setState] = useState({
    map: undefined,
  });

  // Calculate view extent etc.
  const { viewExtent, viewProjection, dataProjection } = useMemo(() => {
    var rW = mapImageWidth / detectionImageWidth;
    var rH = mapImageHeight / detectionImageHeight;
    var extent = [0, 0, mapImageWidth, mapImageHeight];
    var viewExtent = [0, -mapImageHeight, mapImageWidth, 0];

    var dataProjection = new Projection({
      code: "itineris-camera",
      units: "pixels",
      extent: extent,
    });

    var viewProjection = new Projection({
      code: "itineris-image",
      units: "pixels",
      extent: viewExtent,
    });

    addCoordinateTransforms(
      dataProjection,
      viewProjection,
      function (coordinate) {
        return [rW * coordinate[0], -(rH * coordinate[1])];
      },
      function (coordinate) {
        return [coordinate[0] / rW, Math.abs(coordinate[1] / rH)];
      }
    );

    return { viewExtent, extent, rW, rH, dataProjection, viewProjection };
  }, [
    detectionImageHeight,
    detectionImageWidth,
    mapImageHeight,
    mapImageWidth,
  ]);

  // Update features
  const updateFeatures = useCallback(() => {
    vectorSource.clear();

    var format = new GeoJSON({
      dataProjection,
      featureProjection: viewProjection,
    });

    var features = format.readFeatures({
      type: "FeatureCollection",
      features: crossfilter.allFiltered().slice(0, 5000),
    });

    vectorSource.addFeatures(features);
  }, [crossfilter, dataProjection, vectorSource, viewProjection]);

  // Calculate image size
  useEffect(() => {
    var img = new Image();
    img.onload = () => {
      setDims([img.width, img.height]);
    };
    img.src = mapImage;
  }, [setDims, mapImage]);

  // Add change handler to crossfilter
  useEffect(() => {
    var to = null;

    // Perform reload on clear
    let removeHandler = crossfilter.onChange((evt) => {
      if (evt === "filtered" || evt === "dataAdded") {
        if (to) clearInterval(to);
        to = setTimeout(() => {
          updateFeatures();
        }, 200);
      }
    });

    // Remove eventhandler on unload
    return () => {
      removeHandler();
      clearTimeout(to);
    };
  }, [crossfilter, updateFeatures]);

  useEffect(() => {
    if (!dims || dims.length < 2) return;

    ref.current.innerHTML = "";

    updateFeatures();

    var map = new Map({
      controls: defaultControls().extend([]),
      layers: [
        new ImageLayer({
          source: new Static({
            attributions: "© Asplan Viak 2023",
            url: mapImage,
            projection: viewProjection,
            imageExtent: viewExtent,
          }),
        }),
        vectorLayer,
        vectorLayerILine,
      ],
      target: ref.current,
      view: new View({
        projection: viewProjection,
        center: getCenter(viewExtent),
        zoom: 2,
        maxZoom: 8,
      }),
    });

    // Update state
    setState((s) => ({
      dataProjection,
      map,
      viewProjection,
    }));
  }, [
    dataProjection,
    dims,
    mapImage,
    updateFeatures,
    vectorLayer,
    vectorLayerILine,
    viewExtent,
    viewProjection,
  ]);

  return (
    <TrackMapContext.Provider
      value={{
        ...state,
        dataProjection,
        viewProjection,
        vectorSourceDraw,
        vectorSourceILine,
      }}
    >
      <div className="ol-counter-chart" ref={ref}></div>
      {state.map !== undefined && (
        <>
          <CtrlZoomExtent extent={viewExtent} />
          <CtrlClearVectorSource
            onCleared={() => {
              setWktLine(null);
            }}
          />
          <InteractionDrawWkt wktLine={wktLine} setWktLine={setWktLine} />
          {children}
        </>
      )}
    </TrackMapContext.Provider>
  );
}

TrackMap.propTypes = {
  crossfilter: PropTypes.object.isRequired,
  colorScale: PropTypes.func.isRequired,
  mapImage: PropTypes.string.isRequired,
  mapImageWidth: PropTypes.number.isRequired,
  mapImageHeight: PropTypes.number.isRequired,
  setWktLine: PropTypes.func.isRequired,
  wktLine: PropTypes.any,
};

TrackMap.defaultProps = {
  colorScale: d3.scaleOrdinal(d3.schemeCategory10),
  mapImage: "/capture.jpg",
  setWktLine: (_wktLine) => null,
};

export default TrackMap;
