import { useContext, useEffect } from "react";
import { CreateControl } from "../common/CreateControl";
import { AVTraceMapContext } from "./AVTraceMap";

export function AVTraceCtrlZoomExtent({ top = "65px" }) {
  const ctx = useContext(AVTraceMapContext);
  useEffect(() => {
    if (!ctx) return;
    const { map, viewExtent } = ctx;
    let ctrl = CreateControl({
      symbol: "▢",
      className: "zoom-full-extent",
      handler: () => {
        map.getView().fit(viewExtent);
      },
      top,
    });
    map.addControl(new ctrl());
  }, [ctx, top]);

  return null;
}
