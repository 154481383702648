import { useEffect } from "react";
import { AVTraceMapContext } from "./AVTraceMap";
import VectorSource from "ol/source/Vector.js";
import { Heatmap as OLHeatmapLayer } from "ol/layer.js";
import { useContext } from "react";
import { useCallback } from "react";
import GeoJSON from "ol/format/GeoJSON";
import { useState } from "react";
import { useMemo } from "react";
import * as d3 from "d3";

const scaleWeight = d3.scaleLinear().domain([0, 123]).range([0.1, 1.0]);

export function HeatmapLayer({
  valueProp,
  radius = 5,
  blur = 20,
  opacity = 0.5,
}) {
  // Get map object from context
  const { map, dataProjection, viewProjection, crossfilter } =
    useContext(AVTraceMapContext) ?? {};

  // GeoJSON format parser
  const geoJsonFormat = useMemo(
    () =>
      new GeoJSON({
        dataProjection,
        featureProjection: viewProjection,
      }),
    [dataProjection, viewProjection]
  );

  // Vector source object
  const [vectorSource] = useState(
    new VectorSource({
      features: [],
    })
  );

  const weightFunc = useCallback(
    (feature) => {
      return scaleWeight(feature.get(valueProp));
    },
    [valueProp]
  );

  // Heatmap layer object
  const [heatmapLayer] = useState(
    new OLHeatmapLayer({
      source: vectorSource,
      blur: parseInt(blur, 10),
      radius: parseFloat(radius, 10),
      opacity: parseFloat(opacity, 10),
      weight: weightFunc,
    })
  );

  const updateFeatures = useCallback(() => {
    // If no crossfilter, return
    if (!crossfilter) return;

    // Empty existing vector source
    vectorSource.clear();

    // Calc stats
    // console.log(crossfilter.allFiltered().slice(0, 10));
    let series = crossfilter.allFiltered().map((d) => d.properties[valueProp]);
    const [min, max] = d3.extent(series);
    scaleWeight.domain([min, max]);

    // Parse features from filter, no limits
    var features = geoJsonFormat.readFeatures({
      type: "FeatureCollection",
      features: crossfilter.allFiltered(),
    });

    // Add features to vector source
    vectorSource.addFeatures(features);
  }, [crossfilter, vectorSource, geoJsonFormat, valueProp]);

  useEffect(() => {
    // Update heat map effects
    if (heatmapLayer) {
      heatmapLayer.setRadius(parseInt(radius, 10));
      heatmapLayer.setBlur(parseInt(blur, 10));
      heatmapLayer.setOpacity(parseFloat(opacity, 10));
    }
  }, [opacity, blur, radius, heatmapLayer]);

  // Add handler to crossfilter
  useEffect(() => {
    if (!crossfilter) return null;

    let to;

    let onChangeHandler = (evt) => {
      if (evt === "filtered" || evt === "dataAdded") {
        if (to) clearTimeout(to);
        to = setTimeout(() => {
          updateFeatures();
        }, 200);
      }
    };
    let removeHandler = crossfilter.onChange(onChangeHandler);
    return () => {
      removeHandler();
    };
  }, [crossfilter, updateFeatures]);

  // Initial setup of heatmap layer
  useEffect(() => {
    // If crossfilter is not valid return
    if (!crossfilter || !map) return;

    // If heatmap layer valueProp equals current valueProp, return
    if (heatmapLayer.get("valueProp") === valueProp) return;

    // Remove current layer
    map.removeLayer(heatmapLayer);

    // Set valueProp on heatmap layer
    heatmapLayer.set("valueProp", valueProp);

    // Update features in vector source
    updateFeatures(vectorSource);

    map.addLayer(heatmapLayer);

    return () => {};
  }, [
    crossfilter,
    map,
    updateFeatures,
    blur,
    radius,
    opacity,
    valueProp,
    heatmapLayer,
    vectorSource,
  ]);

  return null;
}
