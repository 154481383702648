import "./Login.scss";

import { Card, Container, Image } from "react-bootstrap";

import LoginForm from "../components/LoginForm";
import logo from "./../avverktoy-dark.svg";
import { toast } from "react-toastify";
import { useEffect, useRef } from "react";

export function Login() {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current.play();
  }, []);

  return (
    <Container fluid color="light" className="login-container">
      <video autoPlay muted loop id="myVideo" ref={videoRef}>
        <source src="bg.mp4" type="video/mp4" />
      </video>
      <Card color="primary" className="login-jumbo">
        <Card.Header className="p-3">
          <Card.Title>
            <h1>
              <Image src={logo} className="av-logo" alt="AV verktøy-logo" />
              {process.env.REACT_APP_TITLE.replace("AV ", "")}
            </h1>
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-3">
          <h3>Logg inn</h3>
          <LoginForm
            onSuccess={() => {
              toast.success("Success logging in");
            }}
            onError={(err) => {
              toast.error(err);
            }}
            redirect="/projects"
          />
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Login;
