import './PageTitle.scss'

import { PropTypes } from 'prop-types';
import logo from './../avverktoy-dark.svg';

export function PageTitle({ subpage }) {
    return (<div className="title-area">
        <img className="home-logo" src={logo} alt="AV verktøy-logo" />
        <h1>{process.env.REACT_APP_TITLE.replace("AV ", "")}{subpage ? ` • ${subpage}`: ''}</h1>
    </div>)
}

PageTitle.propTypes = {
    subpage: PropTypes.string
};

export default PageTitle;

