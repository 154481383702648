import superagent from "superagent";

export function apiGet(fragment, onProgress = (e) => {}) {
  return superagent
    .get(`${process.env.REACT_APP_WEB_API}${fragment}`)
    .on("progress", onProgress)
    .catch((err) => {
      console.error(err);
      return Promise.resolve(err);
    });
}

export function apiPost(fragment, data = {}, onProgress = (e) => {}) {
  return superagent
    .post(`${process.env.REACT_APP_WEB_API}${fragment}`, data)
    .on("progress", onProgress)
    .catch((err) => {
      console.error(err);
      return Promise.reject(err);
    });
}

export function apiProjectImage(projectId) {
  return `${process.env.REACT_APP_WEB_API}Project/Image/${projectId}`;
}

export function apiProjectImageDownload(projectId) {
  return `${process.env.REACT_APP_WEB_API}Project/Image/Download/${projectId}`;
}

export function apiImage(projectId, recordingId) {
  return `${process.env.REACT_APP_WEB_API}admin/get-project-recording-image/${projectId}/${recordingId}`;
}

const AVTRACE_AUTH_KEY = "avtrace_auth";

export function setAuthenticated(token) {
  return localStorage.setItem(AVTRACE_AUTH_KEY, JSON.stringify(token));
}

export function getAuthenticated() {
  try {
    return JSON.parse(localStorage.getItem(AVTRACE_AUTH_KEY));
  } catch (error) {
    return undefined;
  }
}

export function clearAuthenticated() {
  return localStorage.setItem(AVTRACE_AUTH_KEY, undefined);
}

export function isAuthenticated() {
  var s = getAuthenticated();
  if (!s) return false;
  if (s.username && new Date(s.expires) > new Date()) return true;
}

export function getTrackerSummary(data) {
  if (typeof data.trackerSummary === "object") {
    return data.trackerSummary.totalItemsTracked || 0;
  }
  return "n/a";
}

export function getCounterSummary(data) {
  if (typeof data.counterSummary === "object") {
    return Object.keys(data.counterSummary)
      .map((areaId) => data.counterSummary[areaId]._total)
      .reduce((p, v) => p + v, 0);
  }
  return "n/a";
}
