import "./HeatMap.css";
import "dc/dc.css";
import "ol/ol.css";

import * as d3 from "d3";
import dc from "dc";
import React, { useEffect, useState } from "react";

import { BarChartOrdinal, BarChart } from "avinet-dashboard-lib";
import { apiGet } from "../common/Utils";

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
  Stack,
} from "react-bootstrap";
import { Loading } from "../components/Loading";
import crossfilter from "crossfilter2";
import { useParams } from "react-router";
import { AVTraceMap } from "../components/AVTraceMap";
import { NoData } from "../components/NoData";
import { useCallback } from "react";
import { useContext } from "react";
import { SingleProjectContext } from "../routers/SingleProjectRouter";
import { AVDateRangePicker } from "../components/AVDateRangePicker";
import moment from "moment-timezone";
import { colors, objClassColors } from "../common/ColorUtils";

export function HeatMap({ radius = 10, blur = 20, opacity = 0.5 }) {
  const [cf] = useState(crossfilter([]));
  const [charts, setCharts] = useState(undefined);
  const { projectId } = useParams();
  const [loading, setLoading] = useState(undefined);
  const [noData, setNoData] = useState(false);
  const { filter } = useContext(SingleProjectContext);
  const { project } = filter;
  const [localFilter, setLocalFilter] = useState(filter);
  const { startTime, endTime, timeRes, valueProp } = localFilter;
  const { display_timezone, detection_timezone } = project;

  const loadGrid = useCallback(
    async (timeRes, startTime, endTime) => {
      setLoading(true);
      async function doit() {
        let _startTime = moment(startTime)
          .tz(display_timezone, true)
          .tz(detection_timezone)
          .format("YYYY-MM-DDTHH:mm:ss");
        let _endTime = moment(endTime)
          .tz(display_timezone, true)
          .tz(detection_timezone)
          .format("YYYY-MM-DDTHH:mm:ss");

        await apiGet(
          `Grid/GeoJSON/${project.id}/${timeRes}/${_startTime}/${_endTime}`
        )
          .then((res) => {
            if (
              res.ok &&
              Array.isArray(res.body?.features) &&
              res.body.features.length > 0
            ) {
              res.body.features.forEach((f) => {
                var d = new Date(f.properties.tstamp);
                f.properties.hour = d.getHours();
                f.properties.year = d.getYear();
                f.properties.month = d.getMonth() + 1;
                f.properties.weekday = d.getDay() + 1 < 8 ? d.getDay() + 1 : 7;
              });
              cf.remove((d) => true);
              cf.add(res.body.features);
              dc.redrawAll();
              setNoData(false);
            } else {
              throw res.body;
            }
          })
          .catch((err) => {
            console.error("Error", err);
            setNoData(true);
          });

        setLoading(false);
      }
      doit();
    },
    [cf, detection_timezone, display_timezone, project.id]
  );

  useEffect(() => {
    const { startTime, endTime, timeRes } = localFilter;
    const { project } = filter;
    if (!startTime || !endTime || !project.id) return null;
    loadGrid(timeRes, startTime, endTime);
  }, [filter, localFilter, loadGrid]);

  useEffect(() => {
    if (!cf) return;

    var byType = cf.dimension((d) => d.properties.objclass);
    var grpPassByType = byType.group().reduceCount();

    var byMonth = cf.dimension((d) => d.properties.month);
    var grpByMonth = byMonth.group().reduceCount();

    var byHour = cf.dimension((d) => d.properties.hour);
    var grpPassByHour = byHour.group().reduceCount();

    var byWeekday = cf.dimension((d) => d.properties.weekday);
    var grpPassByWeekday = byWeekday.group().reduceCount();

    setCharts(
      <Container fluid className="d-grid px-3 gap-3">
        <Row>
          <Card>
            <Card.Body>
              <AVTraceMap
                imageHeight={project.image_height}
                imageWidth={project.image_width}
                detectionHeight={project.detection_height}
                detectionWidth={project.detection_width}
                valueProp={valueProp}
                crossfilter={cf}
              />
            </Card.Body>
          </Card>
        </Row>
        <Row className="gap-3">
          <Card as={Col}>
            <Card.Body>
              <BarChartOrdinal
                chartTitle="Antall objekter per tidsenhet "
                dimension={byType}
                group={grpPassByType}
                xAxisLabel="Type objekt"
                colorAccessor={(d) => {
                  return d.key;
                }}
                colors={objClassColors}
              />
            </Card.Body>
          </Card>
          <Card as={Col}>
            <Card.Body>
              <BarChartOrdinal
                chartTitle="Antall objekter etter time i døgnet"
                group={grpPassByHour}
                dimension={byHour}
                x={d3.scaleBand()}
                xUnits={dc.units.ordinal}
                colors={colors}
              />
            </Card.Body>
          </Card>
        </Row>
        <Row className="gap-3">
          <Card as={Col}>
            <Card.Body>
              <BarChart
                chartTitle="Antall objekter etter ukedag"
                group={grpPassByWeekday}
                dimension={byWeekday}
                x={d3.scaleBand()}
                xUnits={dc.units.ordinal}
                colors={colors}
              />
            </Card.Body>
          </Card>
          <Card as={Col}>
            <Card.Body>
              <BarChartOrdinal
                chartTitle="Antall objekter etter måned i året"
                group={grpByMonth}
                dimension={byMonth}
                x={d3.scaleBand()}
                xUnits={dc.units.ordinal}
                brushOn={true}
                colors={colors}
              />
            </Card.Body>
          </Card>
        </Row>
      </Container>
    );
  }, [cf, projectId, setNoData, project, valueProp]);

  const handleChange = useCallback((evt) => {
    setLocalFilter((flt) => ({
      ...flt,
      [evt.target.name]: evt.target.value,
    }));
  }, []);

  const handleDateRangeChange = useCallback(({ startTime, endTime }) => {
    setLocalFilter((lFlt) => ({
      ...lFlt,
      startTime,
      endTime,
    }));
  }, []);

  if (noData) {
    return <NoData />;
  }

  return (
    <>
      <Navbar bg="white" className="px-1">
        <Nav className="ms-auto">
          <Form as={Stack} direction="horizontal" gap={3}>
            <InputGroup>
              <InputGroup.Text>Vis verdi</InputGroup.Text>
              <Form.Select
                name="valueProp"
                value={localFilter.valueProp}
                onChange={handleChange}
              >
                <option value={"detection_count"}>Deteksjoner</option>
                <option value={"detection_nunique"}>Unike personer</option>
                <option value={"duration"}>Tid anvendt</option>
              </Form.Select>
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>Tidsoppløsning</InputGroup.Text>
              <Form.Select
                name="timeRes"
                value={localFilter.timeRes}
                onChange={handleChange}
              >
                <option value={"15T"}>15 min</option>
                <option value={"60T"}>1 hour</option>
                <option value={"180T"}>3 hours</option>
                <option value={"1440T"}>1 day</option>
              </Form.Select>
            </InputGroup>
            {localFilter?.startTime && localFilter?.endTime && (
              <AVDateRangePicker
                projectMeta={filter}
                filter={localFilter}
                handleChange={handleDateRangeChange}
              />
            )}
            {/* <InputGroup>
              <InputGroup.Text>From</InputGroup.Text>
              <Form.Control
                name="startTime"
                value={localFilter.startTime}
                onChange={handleChange}
                type="datetime-local"
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>To</InputGroup.Text>
              <Form.Control
                name="endTime"
                value={localFilter.endTime}
                onChange={handleChange}
                type="datetime-local"
              />
            </InputGroup> */}
            <Button
              onClick={() => loadGrid(timeRes, startTime, endTime)}
              disabled={loading}
              className="text-nowrap"
            >
              Last data
            </Button>
          </Form>
        </Nav>
      </Navbar>
      {charts}
      {loading && <Loading label="Laster grid-data" popup />}
    </>
  );
}

export default HeatMap;
