import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

export function NoData({ popup, message, resetFunc }) {
  if (popup === false) {
    return <p>{message}</p>;
  } else {
    return (
      <Modal.Dialog backdrop="static">
        <Modal.Body className="text-center">
          <h3>{message}</h3>
        </Modal.Body>
        {typeof resetFunc === "function" && (
          <Modal.Footer>
            <Button onClick={() => resetFunc()}>Nullstill</Button>
          </Modal.Footer>
        )}
      </Modal.Dialog>
    );
  }
}

NoData.propTypes = {
  message: PropTypes.string,
  popup: PropTypes.bool,
};

NoData.defaultProps = {
  message: "Ingen data i utvalget",
  popup: false,
};
