import { Form } from "react-bootstrap";
import { PropTypes } from 'prop-types';
import React, { } from 'react';

export function FormField({ name, label, helpText, placeholder, data, setData, type, rows = "3", disabled = false, ...otherProps }) {
    return (
        <Form.Group controlId={name}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                as={type === 'textarea' ? 'textarea' : 'input'}
                type={type}
                placeholder={placeholder ? placeholder : ''}
                rows={rows}
                defaultValue={data[name]}
                disabled={disabled}
                onChange={(e) => {
                    data[name] = e.target.value;
                    setData(data);
                }}
                {...otherProps}
            />
            {helpText && <Form.Text className="text-muted">
                {helpText}
            </Form.Text>}
        </Form.Group>
    )
}


FormField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["number", "radio", "text", "url", "time", "checkbox", "color", "tel", "password", "textarea"]).isRequired,
    help: PropTypes.string,
    placeholder: PropTypes.string
}

FormField.defaultProps = {
    type: "text"
}

export default FormField