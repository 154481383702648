import { Redirect, Route } from "react-router-dom";

import { PropTypes } from 'prop-types';
import React from "react";
import { isAuthenticated } from './../common/Utils';

export function PrivateRoute({
    children,
    path,
    exact,
    loginRoute
}) {

    return (
        <Route path={path} exact={exact}>
            {isAuthenticated() ? children : <Redirect to={loginRoute} />}
        </Route>
    );
};

PrivateRoute.propTypes = {
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool.isRequired,
}

PrivateRoute.defaultProps = {
    exact: false,
    loginRoute: '/login'
}

export default PrivateRoute;