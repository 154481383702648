import { Button, Modal } from "react-bootstrap";

export function Dialog(props) {

    const { show = false, title = "", closeLabel = "Lukk", onClose = () => { }, children } = props;

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        {closeLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Dialog