import { useContext } from "react";
import { StateContext } from "../context/AuthContext";

export function RequireAuth({ children = true }) {
  const { state } = useContext(StateContext);
  const { authorized } = state;
  if (!authorized) {
    return null;
  } else {
    return <>{children}</>;
  }
}

export function RequireNotAuth({ children = true }) {
  const { state } = useContext(StateContext);
  const { authorized } = state;
  if (authorized) {
    return null;
  } else {
    return <>{children}</>;
  }
}
