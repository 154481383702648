import * as d3 from "d3";
import dc from "dc";

dc.config.defaultColors(["#ff0000", "#00ff00", "#0000ff"]);

export const objClassColors = d3
  .scaleOrdinal()
  .domain(["p", "person", "sykkel", "varebil", "lastebil"])
  .range(["#0099CC", "#0099CC", "#2200CC", "#FF9900", "#FF0000"]);

export const colors = d3.scaleOrdinal().range(d3.schemeCategory10);
