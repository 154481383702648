import React, { useContext } from "react";
import { Badge, Button, Nav, Navbar } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import logo from "../avverktoy.svg";
import { PrivateRoute } from "../components/PrivateRoute";
import { RequireAuth, RequireNotAuth } from "../components/RequireAuth";
import { StateContext } from "../context/AuthContext";
import { Login } from "../views/Login";
import { Logout } from "../views/Logout";
import { ProjectsRouter } from "./ProjectsRouter";

export function MainRouter() {
  const { state } = useContext(StateContext);
  const { authenticatedUser } = state;

  return (
    <Router>
      <Navbar bg="dark" variant="dark" expand="lg" className="px-3">
        <Link to="/">
          <Navbar.Brand>
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            {process.env.REACT_APP_TITLE.replace("AV ", "")}
          </Navbar.Brand>
        </Link>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Link to="/">
              <Button variant="link">Hjem</Button>
            </Link>
            <RequireNotAuth>
              <Link to="/login">
                <Button variant="link">Logg inn</Button>
              </Link>
            </RequireNotAuth>
            <RequireAuth>
              <Link to="/projects">
                <Button variant="link">Prosjekt</Button>
              </Link>
              <Link to="/logout">
                <Button variant="link">Logg ut</Button>
              </Link>
            </RequireAuth>
          </Nav>
          <RequireAuth>
            <Badge text="dark" bg="primary">
              {authenticatedUser?.username}
            </Badge>
          </RequireAuth>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Navbar>
      <>
        <Switch>
          <Route exact path="/">
            <Redirect to="/projects" />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <PrivateRoute exact path="/logout">
            <Logout />
          </PrivateRoute>
          <PrivateRoute path="/projects" loginRoute="/login">
            <ProjectsRouter />
          </PrivateRoute>
        </Switch>
      </>
    </Router>
  );
}
