import { useContext, useEffect } from "react";
import { CreateControl } from "../common/CreateControl";
import { TrackMapContext } from "./TrackMap";

export function CtrlClearVectorSource({ onCleared = undefined, top = "95px" }) {
  const { map, vectorSourceILine } = useContext(TrackMapContext);
  useEffect(() => {
    if (!map || !vectorSourceILine) return;
    var ctrl = CreateControl({
      symbol: "X",
      className: "clear-line",
      handler: () => {
        vectorSourceILine.clear();
        if (typeof onCleared === "function") {
          onCleared();
        }
      },
      top,
    });

    map.addControl(new ctrl());
  }, [map, vectorSourceILine, onCleared, top]);

  return null;
}
