import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";
import { ProjectList } from "../views/ProjectList";
import { SingleProjectRouter } from "./SingleProjectRouter";

export function ProjectsRouter() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={`${path}/:projectId`}>
        <SingleProjectRouter />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}`}>
        <ProjectList />
      </PrivateRoute>
    </Switch>
  );
}
