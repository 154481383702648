import { Nav, Navbar } from "react-bootstrap";

import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { SingleProjectContext } from "../routers/SingleProjectRouter";

export function SingleProjectNavBar() {
  const { filter } = useContext(SingleProjectContext);
  const { project } = filter;

  if (!project) return null;

  return (
    <>
      <Navbar bg="primary" className="px-3">
        <Navbar.Brand href="#home">
          <h3>{project.title}</h3>
        </Navbar.Brand>
        <Nav className="ms-auto">
          <NavLink to={`/projects/${project.id}`} className="px-3">
            Info
          </NavLink>
          <NavLink to={`/projects/${project.id}/tracker`} className="px-3">
            Sporing
          </NavLink>
          <NavLink to={`/projects/${project.id}/heatmap`} className="px-3">
            Heatmap
          </NavLink>
        </Nav>
      </Navbar>
    </>
  );
}
