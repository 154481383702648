import { Modal, Spinner } from "react-bootstrap";

import { PropTypes } from "prop-types";

export function Loading({ label, popup }) {
  if (popup === false) {
    return (
      <>
        <Spinner animation="border" size="sm" role="status" /> {label}
      </>
    );
  } else {
    return (
      <div
        className="modal show"
        style={{
          display: "block",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "rgba(24, 24, 24, 0.5)",
        }}
      >
        <Modal.Dialog backdrop="static" size="sm" centered={true}>
          <Modal.Body className="text-center">
            <Spinner animation="border" role="status" className="my-3" />
            <h4>{label}</h4>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
}

Loading.propTypes = {
  label: PropTypes.string.isRequired,
  popup: PropTypes.bool,
};

Loading.defaultProps = {
  label: "Laster...",
  popup: false,
};

export default Loading;
