import { clearAuthenticated } from "./../common/Utils";
import { setAuthenticated } from "../common/Utils";

export const AuthReducer = (state, dispatch) => {
  switch (dispatch.action) {
    case "LOGIN":
      setAuthenticated(dispatch.payload);
      return {
        ...state,
        authorized: true,
        authenticatedUser: dispatch.payload,
      };
    case "LOGOUT":
      clearAuthenticated();
      return {
        ...state,
        authorized: false,
        authenticatedUser: undefined,
      };
    default:
      console.warn(
        `Unsupported dispatch action: ${dispatch.action}, returning state unchanged`
      );
      return state;
  }
};
