import "./Tracker.css";
import "dc/dc.css";
import moment from "moment-timezone";

import * as d3 from "d3";

import { BarChart, BarChartOrdinal, PivotTable } from "avinet-dashboard-lib";
import React, { useCallback, useMemo, useRef, useState } from "react";

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
  Stack,
} from "react-bootstrap";
import { Loading } from "../components/Loading";
import {
  apiGet,
  apiPost,
  apiProjectImage,
  apiProjectImageDownload,
} from "../common/Utils";
import crossfilter from "crossfilter2";
import dc from "dc";
import { useParams } from "react-router";
import TrackMap from "../components/TrackMap";
import { NoData } from "../components/NoData";
import { downloadImage, downloadObjectAsJson } from "../common/DownloadUtils";
import { useContext } from "react";
import { SingleProjectContext } from "../routers/SingleProjectRouter";
import { useEffect } from "react";
import { AVDateRangePicker } from "../components/AVDateRangePicker";
import { colors, objClassColors } from "../common/ColorUtils";

const formatDate = d3.timeFormat("%Y-%m-%d");

export function Tracker() {
  const [data, setData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [cf] = useState(crossfilter([]));
  const dimensions = useRef([]);
  const [loading, setLoading] = useState(false);
  const [intersectGeom, setIntersectGeom] = useState();
  const { projectId } = useParams();
  const { filter } = useContext(SingleProjectContext);
  const { project, endTime, startTime } = filter;
  const {
    detection_height,
    detection_width,
    image_height,
    image_width,
    detection_timezone,
    display_timezone,
  } = project ?? {};
  const [localFilter, setLocalFilter] = useState({
    startTime,
    endTime,
  });

  const updateDashboard = useCallback(
    (features, objectClasses) => {
      if (objectClasses.length === 0) return;

      features.forEach((f1, i) => {
        var dt = moment(f1.properties.start_time)
          .tz(detection_timezone, true)
          .tz(display_timezone)
          .toDate();
        f1.properties.timestamp = dt;
        f1.properties.weekday = dt.getDay() === 0 ? 7 : dt.getDay();
        f1.properties.month = dt.getMonth() + 1;
        f1.properties.hour = dt.getHours();
        f1.properties.year = dt.getFullYear();
        f1.properties.date = formatDate(dt) || "";
      });
      console.log("Loaded");
      var data = features.filter(
        (f2) => objectClasses.indexOf(f2.properties.objclass) > -1
      );
      setData(data);
      dimensions.current.forEach((dim) => {
        if (dim.hasCurrentFilter()) {
          dim.filter(null);
        }
      });
      cf.remove((d) => true);
      cf.add(data);
      dc.redrawAll();
    },
    [cf, detection_timezone, display_timezone]
  );

  const loadTracks = useCallback(
    async (intersectGeom = undefined) => {
      const { project } = filter;
      const { startTime, endTime } = localFilter;

      //convert timezone
      let _startTime = moment(startTime)
        .tz(display_timezone, true)
        .tz(detection_timezone)
        .format("YYYY-MM-DDTHH:mm:ss");
      let _endTime = moment(endTime)
        .tz(display_timezone, true)
        .tz(detection_timezone)
        .format("YYYY-MM-DDTHH:mm:ss");

      setLoading(true);
      var req;
      if (!intersectGeom) {
        req = apiGet(`Track/GeoJSON/${project.id}/${_startTime}/${_endTime}`);
      } else {
        req = apiPost(`Track/GeoJSON/Intersect`, {
          projectId: project.id,
          startTime: _startTime,
          endTime: _endTime,
          intersectGeom,
        });
      }
      await req.then((res) => {
        if (res.ok && Array.isArray(res.body?.features)) {
          var objectClasses = res.body.features.reduce((acc, curr) => {
            if (acc.indexOf(curr.properties.objclass) === -1) {
              acc.push(curr.properties.objclass);
            }
            return acc;
          }, []);
          updateDashboard(res.body.features, objectClasses);
        } else {
          setNoData(true);
        }
      });
      setLoading(false);
    },
    [filter, localFilter, display_timezone, detection_timezone, updateDashboard]
  );

  useEffect(() => {
    loadTracks(intersectGeom);
  }, [loadTracks, intersectGeom]);

  const charts = useMemo(() => {
    if (Array.isArray(data) && data.length > 0 && cf) {
      var byType = cf.dimension((d) => d.properties.objclass);
      dimensions.current.push(byType);
      var grpPassByType = byType.group().reduceCount();

      let dmap = ["Sø", "Må", "Ty", "On", "To", "Fr", "Lø"];

      var byMonth = cf.dimension((d) => d.properties.month);
      dimensions.current.push(byMonth);
      var grpByMonth = byMonth.group().reduceCount();

      var byHour = cf.dimension((d) => d.properties.hour);
      dimensions.current.push(byHour);
      var grpPassByHour = byHour.group().reduceCount();

      // var byYear = cf.dimension((d) => d.properties.year);
      // dimensions.current.push(byYear);
      // var grpPassByYear = byYear.group().reduceCount();

      var byWeekday = cf.dimension((d) => dmap[d.properties.weekday]);
      dimensions.current.push(byWeekday);
      var grpPassByWeekday = byWeekday.group().reduceCount();

      var byDate = cf.dimension((d) => d.properties.date);
      dimensions.current.push(byDate);
      var grpPassByDate = byDate.group().reduceCount();

      var crossingBehaviourMap = {
        0: "N/A",

        "-1": "Mot venstre",

        1: "Mot høyre",

        "-2": "Mot venstre (+)",

        2: "Mot høyre (+)",

        "-3": "Fra/til venstre",

        3: "Fra/til høyre",
      };

      var byCrossingBehavior = cf.dimension(
        (d) => crossingBehaviourMap[d.properties.crossing_behaviour]
      );
      dimensions.current.push(byCrossingBehavior);
      var grpbyCrossingBehavior = byCrossingBehavior.group().reduceCount();

      var byWeekDayHour = cf.dimension((d) => [
        d.properties.weekday,
        d.properties.hour,
      ]);
      dimensions.current.push(byWeekDayHour);
      var grpPassByWeekDayHour = byWeekDayHour.group().reduceCount();

      dc.config.defaultColors(d3.schemeTableau10);

      return (
        <Container fluid className="d-grid px-3 gap-3">
          <Row>
            <Card as={Col}>
              <Card.Body>
                <TrackMap
                  crossfilter={cf}
                  mapImage={apiProjectImage(projectId)}
                  mapImageWidth={image_width}
                  mapImageHeight={image_height}
                  detectionImageWidth={detection_width}
                  detectionImageHeight={detection_height}
                  wktLine={intersectGeom}
                  setWktLine={setIntersectGeom}
                  colorScale={objClassColors}
                />
              </Card.Body>
              <Card.Footer className="text-end">
                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={() => {
                    downloadObjectAsJson(
                      data,
                      "tracks-" + new Date().toISOString() + ".geojson"
                    );
                  }}
                >
                  Last ned data
                </Button>
                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={() => {
                    downloadImage(apiProjectImageDownload(projectId));
                  }}
                >
                  Last ned bilde
                </Button>
              </Card.Footer>
            </Card>
          </Row>
          <Row className="gap-3">
            <Card as={Col}>
              <Card.Body>
                <BarChartOrdinal
                  chartTitle="Antall spor etter type objekt"
                  dimension={byType}
                  group={grpPassByType}
                  xAxisLabel="Type objekt"
                  colorAccessor={(d) => {
                    return d.key;
                  }}
                  colors={objClassColors}
                />
              </Card.Body>
            </Card>
            <Card as={Col}>
              <Card.Body>
                <BarChartOrdinal
                  chartTitle="Antall spor etter retning"
                  dimension={byCrossingBehavior}
                  group={grpbyCrossingBehavior}
                  xAxisLabel="Retning"
                  rotateXAxisLabels={-45}
                  colorAccessor={(d) => {
                    return d.key;
                  }}
                  colors={colors}
                />
              </Card.Body>
            </Card>
            <Card as={Col}>
              <Card.Body>
                <BarChartOrdinal
                  chartTitle="Antall spor etter time i døgnet"
                  group={grpPassByHour}
                  dimension={byHour}
                  x={d3.scaleBand()}
                  xUnits={dc.units.ordinal}
                  colors={colors}
                />
              </Card.Body>
            </Card>
          </Row>
          <Row className="gap-3">
            {/* <Card as={Col}>
              <Card.Body>
                <BarChartOrdinal
                  chartTitle="Antall spor etter år"
                  group={grpPassByYear}
                  dimension={byYear}
                  x={d3.scaleBand()}
                  xUnits={dc.units.ordinal}
                  colors={colors}
                />
              </Card.Body>
            </Card> */}
            <Card as={Col}>
              <Card.Body>
                <BarChartOrdinal
                  chartTitle="Antall spor etter måned i året"
                  group={grpByMonth}
                  dimension={byMonth}
                  x={d3.scaleBand()}
                  xUnits={dc.units.ordinal}
                  brushOn={true}
                  colors={colors}
                />
              </Card.Body>
            </Card>
            <Card as={Col}>
              <Card.Body>
                <BarChart
                  chartTitle="Antall spor etter ukedag"
                  group={grpPassByWeekday}
                  dimension={byWeekday}
                  x={d3.scaleBand()}
                  xUnits={dc.units.ordinal}
                  colors={colors}
                />
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Card as={Col}>
              <Card.Body>
                <BarChart
                  chartTitle="Antall spor etter dato"
                  group={grpPassByDate}
                  dimension={byDate}
                  x={d3.scaleBand()}
                  xUnits={dc.units.ordinal}
                  colors={colors}
                />
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <PivotTable
              chartTitle="Antall spor etter ukedag og time i døgnet"
              dimension={byWeekDayHour}
              group={grpPassByWeekDayHour}
              showColTotalRow
              showRowTotalCol
              showPctRow
              columnOrder={(c1, c2) => {
                c1 = parseInt(c1, 10);
                c2 = parseInt(c2, 10);
                if (c1 > c2) return 1;
                if (c2 > c1) return -1;
                return 0;
              }}
              colTotalLabel="Sum per time"
              rowTotalLabel="Sum per ukedag"
              pctRowLabel="Prosent"
            />
          </Row>
        </Container>
      );
    } else {
      return undefined;
    }
  }, [
    data,
    cf,
    projectId,
    image_width,
    image_height,
    detection_width,
    detection_height,
    intersectGeom,
  ]);

  const handleDateRangeChange = useCallback(({ startTime, endTime }) => {
    setLocalFilter((lFlt) => ({
      ...lFlt,
      startTime,
      endTime,
    }));
  }, []);

  // const handleChange = useCallback((evt) => {
  //   setLocalFilter((lFlt) => ({
  //     ...lFlt,
  //     [evt.target.name]: evt.target.value,
  //   }));
  // }, []);

  if (noData) {
    return <NoData popup />;
  }

  return (
    <>
      <Navbar bg="white" className="px-1">
        <Nav className="ms-auto">
          <Form as={Stack} direction="horizontal" gap={3}>
            {localFilter?.startTime && localFilter?.endTime && (
              <AVDateRangePicker
                projectMeta={filter}
                filter={localFilter}
                handleChange={handleDateRangeChange}
              />
            )}
            {/* </InputGroup> */}
            {/* <InputGroup>
              <InputGroup.Text>From</InputGroup.Text>
              <Form.Control
                name="startTime"
                value={localFilter.startTime}
                onChange={handleChange}
                type="datetime-local"
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Text>To</InputGroup.Text>
              <Form.Control
                name="endTime"
                value={localFilter.endTime}
                onChange={handleChange}
                type="datetime-local"
              />
            </InputGroup> */}
            <Button
              onClick={() => loadTracks()}
              disabled={loading}
              className="text-nowrap"
            >
              Last data
            </Button>
          </Form>
        </Nav>
      </Navbar>
      {charts}
      {loading && <Loading label="Laster dashboard data" popup />}
    </>
  );
}

export default Tracker;
