import "./SingleProject.css";

import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";

import { Loading } from "./../components/Loading";
import { apiProjectImage } from "./../common/Utils";
import { useMemo } from "react";
import { Dt } from "../common/DateUtils";
import { useContext } from "react";
import { SingleProjectContext } from "../routers/SingleProjectRouter";

export function SingleProject() {
  const { projectId } = useParams();
  const { filter } = useContext(SingleProjectContext);
  const { project, detectionMeta } = filter;

  const [startTime, endTime, duration] = useMemo(() => {
    var d0 = new Dt(detectionMeta.start_time);
    var d1 = new Dt(detectionMeta.end_time);
    return [
      d0.trunc("second").toISOString(true),
      d1.trunc("second").toISOString(true),
      d1.subtract(d0),
    ];
  }, [detectionMeta]);

  if (!project) {
    return <Loading label="Laster prosjekt" popup />;
  }

  return (
    <Container fluid className="gap-3 p-3">
      <Row className="gap-3">
        <Card as={Col}>
          <Card.Img variant="top" src={apiProjectImage(projectId)} />
          <Card.Body>
            <Card.Text>{project.description}</Card.Text>
          </Card.Body>
        </Card>
        <Card as={Col}>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Start time</td>
                  <td>{startTime}</td>
                </tr>
                <tr>
                  <td>End</td>
                  <td>{endTime}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>{duration}</td>
                </tr>
                <tr>
                  <td>Image width</td>
                  <td>{project.image_width}</td>
                </tr>
                <tr>
                  <td>Image height</td>
                  <td>{project.image_height}</td>
                </tr>
                <tr>
                  <td>Detection width</td>
                  <td>{project.detection_width}</td>
                </tr>
                <tr>
                  <td>Detection height</td>
                  <td>{project.detection_height}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default SingleProject;
