/**
 * Vanilla javascript date/time manipulation
 */
class Dt {
  constructor(dt) {
    this.dt = dt ? new Date(dt) : new Date();
  }

  /**
   * Add minutes to date
   * @param {number} mins
   * @returns
   */
  addMinutes(mins) {
    this.dt.setMinutes(this.dt.getMinutes() + mins);
    return this;
  }

  /**
   * Add hours to date
   * @param {number} hours
   * @returns
   */
  addHours(hours) {
    this.dt.setHours(this.dt.getHours() + hours);
    return this;
  }

  /**
   * Add days to date
   * @param {number} days
   * @returns
   */
  addDays(days) {
    this.dt.setDate(this.dt.getDate() + days);
    return this;
  }

  /**
   * Add months to date
   * @param {number} months
   * @returns
   */
  addMonths(months) {
    this.dt.setMonth(this.dt.getMonth() + months);
    return this;
  }

  /**
   * Truncate to level
   * @param {*} s A datestring 2023-05-11T14:40:24.302
   * @param {*} level One of month, day, hour, minute, second, millisecond
   * @returns
   */
  trunc(level = undefined) {
    switch (level) {
      case "month":
        this.dt.setMilliseconds(0);
        this.dt.setSeconds(0);
        this.dt.setMinutes(0);
        this.dt.setHours(0);
        this.dt.setDate(1);
        break;
      case "day":
        this.dt.setMilliseconds(0);
        this.dt.setSeconds(0);
        this.dt.setMinutes(0);
        this.dt.setHours(0);
        break;
      case "hour":
        this.dt.setMilliseconds(0);
        this.dt.setSeconds(0);
        this.dt.setMinutes(0);
        break;
      case "minute":
        this.dt.setMilliseconds(0);
        this.dt.setSeconds(0);
        break;
      case "second":
        this.dt.setMilliseconds(0);
        break;
      case "millisecond":
        this.dt.setMilliseconds(0);
        break;
      default:
      // noop
    }
    return this;
  }

  /**
   * Return date formatted as an ISO-string
   * @param {bool} tz Optionally, corret to local timezone
   * @returns
   */
  toISOString(tz = false) {
    var tzoffset = 0;
    if (tz) {
      tzoffset = new Date().getTimezoneOffset() * 60000;
    }
    var d = new Date(this.dt - tzoffset).toISOString().slice(0, -1);
    return d;
  }

  toString() {
    return this.toISOString();
  }

  DAY = 24 * 60 * 60 * 1000;
  HOUR = 60 * 60 * 1000;
  MINUTE = 60 * 1000;
  SECOND = 1000;

  subtract(other, abs = false) {
    var iv = this.dt - other.dt;

    if (abs) {
      iv = Math.abs(iv);
    }
    var days = (iv - (iv % this.DAY)) / this.DAY;
    iv -= days * this.DAY;

    var hours = (iv - (iv % this.HOUR)) / this.HOUR;
    iv -= hours * this.HOUR;

    var minutes = (iv - (iv % this.MINUTE)) / this.MINUTE;
    iv -= minutes * this.MINUTE;

    var seconds = (iv - (iv % this.SECOND)) / this.SECOND;
    iv -= seconds * this.SECOND;

    return `${days} day(s), ${hours} hour(s), ${minutes} minute(s), ${seconds} second(s)`;
  }
}

export { Dt };
