import { Card, Container } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import { DispatchContext } from "../context/AuthContext";
import { Redirect } from "react-router-dom";
import { clearAuthenticated } from "./../common/Utils";
import { toast } from "react-toastify";

export function Logout() {
  const { dispatch } = useContext(DispatchContext);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    dispatch({ action: "LOGOUT" });
    clearAuthenticated();
    toast.info("Du er logget ut");
    setTimeout(() => {
      setRedirect(true);
    }, 5000);
  }, [dispatch]);

  if (redirect === true) {
    return <Redirect to="/" />;
  }

  return (
    <Container fluid color="light">
      <Card color="primary">
        <h1>Logg ut</h1>
        <p>Takk for besøket, du er nå utlogget</p>
      </Card>
    </Container>
  );
}

export default Logout;
