import { Control } from "ol/control.js";

export function CreateControl({
  symbol = "X",
  className = "custom-control",
  handler = () => console.warn("Not implemented"),
  left = ".5em",
  top = undefined,
  right = undefined,
  bottom = undefined,
}) {
  return class OlCustomControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
      const options = opt_options || {};

      const button = document.createElement("button");
      button.innerHTML = symbol;

      const element = document.createElement("div");
      element.className = `${className} ol-unselectable ol-control`;
      element.appendChild(button);

      element.style.left = left;
      element.style.right = right;
      element.style.top = top;
      element.style.bottom = bottom;

      super({
        element: element,
        target: options.target,
      });

      button.addEventListener("click", handler, false);
    }
  };
}
