import { useEffect } from "react";
import { useContext } from "react";
import { TrackMapContext } from "./TrackMap";
import Draw from "ol/interaction/Draw.js";
import WKT from "ol/format/WKT";
import { useMemo } from "react";
import { useCallback } from "react";

export function InteractionDrawWkt({
  wktLine,
  setWktLine = () => console.warn("Not implemented"),
}) {
  const {
    map,
    dataProjection,
    viewProjection,
    vectorSourceDraw,
    vectorSourceILine,
  } = useContext(TrackMapContext);

  const wktFormat = useMemo(() => new WKT(), []);

  const drawWktLine = useCallback(
    (mWktLine) => {
      if (mWktLine) {
        const feature = wktFormat.readFeature(mWktLine, {
          dataProjection: dataProjection,
          featureProjection: viewProjection,
        });
        vectorSourceILine.clear();
        vectorSourceILine.addFeature(feature);
      } else {
        vectorSourceILine.clear();
      }
    },
    [wktFormat, dataProjection, viewProjection, vectorSourceILine]
  );

  useEffect(() => {
    if (wktLine) {
      drawWktLine(wktLine);
    }

    var drawInteraction = new Draw({
      source: vectorSourceDraw,
      type: "LineString",
    });

    drawInteraction.on("drawend", (evt) => {
      const { feature } = evt;
      var _wkt = wktFormat.writeFeature(feature, {
        dataProjection: dataProjection,
        featureProjection: viewProjection,
      });
      setWktLine(_wkt);
      drawWktLine(_wkt);
    });

    map.addInteraction(drawInteraction);
  }, [
    map,
    dataProjection,
    viewProjection,
    vectorSourceDraw,
    wktFormat,
    setWktLine,
    drawWktLine,
    wktLine,
  ]);

  return null;
}
