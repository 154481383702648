// import './scss/common.scss'

import "./App.scss";
import "dc/dc.css";
import "react-toastify/dist/ReactToastify.css";

import { AuthContext } from "./context/AuthContext";
import React from "react";

import { ToastContainer } from "react-toastify";
import { MainRouter } from "./routers/MainRouter";

function App() {
  return (
    <div className="App">
      <AuthContext>
        <MainRouter />
        <ToastContainer
          position="bottom-center"
          hideProgressBar={true}
          autoClose={3000}
          pauseOnHover
        />
      </AuthContext>
    </div>
  );
}

export default App;
