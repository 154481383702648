import { useContext, useEffect } from "react";
import { CreateControl } from "../common/CreateControl";
import { TrackMapContext } from "./TrackMap";

export function CtrlZoomExtent({ extent, top = "65px" }) {
  const { map } = useContext(TrackMapContext);
  useEffect(() => {
    if (!map || !extent) return;
    var ctrl = CreateControl({
      symbol: "▢",
      className: "zoom-full-extent",
      handler: () => {
        map.getView().fit(extent);
      },
      top,
    });
    map.addControl(new ctrl());
  }, [map, extent, top]);

  return null;
}
